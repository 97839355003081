<template>
  <section>
    <navi-bar>
      {{$t('front.common.withdrawalApply')}}
    </navi-bar>
    <template v-if="type == 'apply'">
      <div class="acountapply">
        <a @click="moneyinfo=!moneyinfo"><img src="@/assets_mobile/img/icon_question.svg" /></a>
      </div>
      <!--ul class="gamemoney">
        <li>{{$t('front.cash.haveGameCash')}}</li>
        <li><span class="gn">{{thousand(coinAmt ? coinAmt.toString(): '0')}}</span>{{$t('front.common.money')}}</li>
      </ul>
      <div class="cashchange">
        <a class="cashchangebtn" @click="moneyChange">{{$t('front.cash.cashChange')}}</a>
      </div-->
      <div class="moneyread">
        <h3><!--img src="@/assets_mobile/img/mypage_point_waring.png" /-->{{$t('front.cash.moneyCheckList')}}</h3>
        <ul>
          <li><!--img src="@/assets_mobile/img/icon_check.png" /--><span>{{$t('front.cash.moneyCheckListN')}}</span></li>
          <li><!--img src="@/assets_mobile/img/icon_check.png" /--><span>{{$t('front.cash.moneyCheckListB')}}</span></li>
          <li><!--img src="@/assets_mobile/img/icon_check.png" /--><span>{{$t('front.cash.moneyCheckListD')}}</span></li>
        </ul>
      </div>
      <ul class="gamemoney">
        <li>{{$t('front.give.outCash')}}</li>
        <li><span class="or">{{thousand(userData.cashAmt)}}</span>{{$t('front.common.money')}}</li>
      </ul>
      <p class="outmoney">{{$t('front.board.withdrawMoney')}}</p>
      <div class="outmoneywrite"><input type="text" class="numb" pattern="[0-9.,]+" :placeholder="$t('front.give.moneyInput')" v-model="getCurrentChargeMoney"/><a>{{$t('front.give.allMoney')}}</a></div>
      <ul class="applimoneybtn">
        <li><a @click="setMoney(10000)">+1{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(50000)">+5{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(100000)">+10{{$t('front.cash.manwon')}}</a></li>
        <li><a @click="setMoney(1000000)">+100{{$t('front.cash.manwon')}}</a></li>
      </ul>
      <p class="outmoney b0">{{$t('front.cash.withdrawPassword')}}</p>
      <div class="outmoneywrite"><input type="text" class="numb w100w" pattern="[0-9.,]+" :placeholder="$t('front.cash.inputPassword')" v-model="cashOutPass"/></div>
      <div class="moneyinfowrap" v-if="moneyinfo">
        <div class="moneyinfoappli">
          <a class="close" @click="moneyinfo=!moneyinfo"><img src="@/assets_mobile/img/icon_delete.svg" /></a>
          <h3 class="applih">{{$t('front.cash.moneyOutInput')}}</h3>
          <p class="applip bb0 pb0">{{$t('front.cash.moneyCheckListP')}}</p>
        </div>

        <!--ul class="appliinput">
          <li>보유 캐시</li>
          <li class="or">{{thousand(userData.cashAmt)}}</li>
        </ul>
        <ul class="appliinput">
          <li>보유 게임머니</li>
          <li class="or">{{thousand(coinAmt ? coinAmt.toString(): '0')}}<a class="myinfochng">캐시전환</a></li>
        </ul>
        <ul class="appliinput">
          <li>출금금액 입력</li>
          <li><input type="text" class="numb" pattern="[0-9.,]+" placeholder="직접 입력시 숫자만 입력해 주세요." v-model="getCurrentChargeMoney"/></li>
        </ul>

        <ul class="moneybtnwrap">
          <li class="one"><a @click="setMoney(10000)">1만원</a></li>
          <li class="one"><a @click="setMoney(30000)">3만원</a></li>
          <li class="one"><a @click="setMoney(50000)">5만원</a></li>
          <li class="two"><a @click="setMoney(100000)">10만원</a></li>
        </ul>
        <ul class="moneybtnwrap">
          <li class="thr"><a @click="setMoney(300000)">30만원</a></li>
          <li class="four"><a @click="setMoney(500000)">50만원</a></li>
          <li class="fiv"><a @click="setMoney(1000000)">100만원</a></li>
          <li class="six"><a @click="setMoney(0)">정정</a></li>
        </ul>

        <ul class="appliinput">
          <li>출금 비밀번호</li>
          <li><input type="password" class="numb" placeholder="비밀번호를 입력하세요." v-model="cashOutPass" /></li>
        </ul-->
      </div>
      <!--div class="moneytab">
        <a :class="{'on' : type == 'apply'}" @click="type = 'apply'"><span>출금신청</span></a>
        <a :class="{'on' : type == 'list'}" @click="type = 'list'"><span>출금내역</span></a>
      </div-->
      <div class="btnwrap">
        <a class="ok" @click="onSubmit">{{$t('front.board.apply')}}</a>
      </div>
      <div class="applylistbtn mb100">
        <a :class="{'on' : type == 'list'}" @click="type = 'list'">{{$t('front.mypage.withdrawalList')}} ></a>
      </div>
    </template>

    <template v-if="type == 'list'">
      <date-filter-mobile @search="onCashListSearch"
                          @update="onChangeDate"
      />

      <div class="gamebetwrap">
        <template v-if="cashList.length > 0">
          <div class="gamebetlist" v-for="item in cashList" v-bind:key="item.cashIdx">
            <a class="close"><img src="@/assets_mobile/img/icon_delete.svg" /></a>
            <!--input type="checkbox" v-model="item.checked"-->
            <ul class="gamebetdetail textleft">
              <li class="ft13">{{computedCashStatus(item.cashStatus).text}} {{dateFormat(item.updDate)}}</li>
              <li class="ft15 mt10">{{$t('front.board.withdrawMoney')}}</li>
            </ul>
            <ul class="gamebetdetail textright">
              <li class="mt30 ft15"><span class="bl fb">{{thousand(item.cashAmt)}}</span> 원</li>
              <!--li class="gr ft13 mt10">{{dateFormat(item.updDate)}}</li-->
            </ul>
            <!--a :class="computedCashStatus(item.cashStatus).class">{{computedCashStatus(item.cashStatus).text}}</a--><!-- class="done cancel" -->
          </div>
        </template>
        <template v-else>
          <div class="gamebetlist">
            {{$t('front.common.notFoundList')}}
          </div>
        </template>
      </div>
      <!--div class="btnwrap">
        <a class="all" @click="onAllCheck('cashList')">{{allChecked ? '전체해제': '전체선택'}}</a>
        <a class="selec" @click="onRemoveMsg">선택삭제</a>
      </div-->
      <pagination-mobile v-if="pageInfo"
                         :pageNum="pageInfo.page"
                         :pageSize="pageInfo.count_per_list"
                         :totalCount="pageInfo.tatal_list_count"
                         @goToPage="onCashListSearchMobile"
      />
      <div class="applylistbtn left mt10 mb100">
        <a :class="{'on' : type == 'apply'}" @click="type = 'apply'">&lt; {{$t('front.common.withdrawalApply')}}</a>
      </div>
    </template>

  </section>
</template>

<script>

import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Exchange from '@/views/member/money/exchange'

export default {
  name: 'exchange',
  data () {
    return {
      moneyinfo: false
    }
  },
  components: {
    NaviBar,
    PaginationMobile,
    DateFilterMobile
  },
  mixins: [
    Exchange
  ]
}
</script>

<style scoped src="@/styles_mobile/betlist.css"></style>
<style scoped src="@/styles_mobile/money.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style>
.morebtn {margin-bottom: 10px !important;}
</style>
